import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { StaticImage } from "gatsby-plugin-image";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

const photoStyles = {
  zIndex: 1,
  width: '100%',
  height: '100%',
  borderRadius: 5,
}

export default function About() {
  return (
    <>
    <Box id="about" sx={{ backgroundColor: '#0069d0'}} >
      <Container maxWidth="md" sx={{ display: { xs: 'none', md: 'block'} }} >
        <Box pt={8} pb={5}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item sm={8}>
              <Container sx={{ borderBottom: '2px solid rgba(247, 106, 243, 1)', marginBottom: '2%', color: 'white' }}>
                <Typography component="h2" variant="h4" gutterBottom>
                  About Me
                </Typography>
              </Container>
              <Typography variant="subtitle1" sx={{ color: 'white' }} paragraph>
                Originally from Southern California, I made the leap to the Bay Area two years ago to pursue my passion for software engineering. Currently, I'm thriving in the fast-paced environment of a startup, where I'm an integral part of the marketplace team. This role allows me to engage with the entire spectrum of our platform, from conceptualizing and implementing new features to managing complex application states on both the Next.js and React Native frontends, as well as optimizing our Node.js backend API to meet evolving needs. I take pride in my ability to ensure the marketplace operates seamlessly, ultimately keeping users delighted and engaged.
              </Typography>
            </Grid>
            <Grid item sx={{ position: "relative" }} sm={3}>
              <StaticImage
                src="../images/david_rl.jpg"
                alt='David Rajec profile image'
                style={photoStyles}
              />
              <Box sx={{ width: "100%", height: "100%", borderRadius: '5px', top: '10px', left: '10px', position: "absolute", border: "1px solid rgba(247, 106, 243, 1)", zIndex: '0' }}></Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container maxWidth="md" sx={{ display: { xs: 'block', md: 'none'} }} >
        <Box pt={8} pb={5}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Container sx={{ borderBottom: '2px solid rgba(247, 106, 243, 1)', marginBottom: '2%', color: 'white' }}>
                <Typography component="h2" variant="h4" gutterBottom>
                  About Me
                </Typography>
              </Container>
              <Typography variant="subtitle1" sx={{ color: 'white' }} paragraph>
                Originally from Southern California, I made the leap to the Bay Area two years ago to pursue my passion for software engineering. Currently, I'm thriving in the fast-paced environment of a startup, where I'm an integral part of the marketplace team. This role allows me to engage with the entire spectrum of our platform, from conceptualizing and implementing new features to managing complex application states on both the Next.js and React Native frontends, as well as optimizing our Node.js backend API to meet evolving needs. I take pride in my ability to ensure the marketplace operates seamlessly, ultimately keeping users delighted and engaged.
              </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
    </>
  );
}
